import { Button, Card, Offcanvas } from "react-bootstrap";
import OffCanvasCloseBtn from "./OffCanvasCloseBtn";
import Slider from "react-slick";
import { IoCheckmarkCircle, IoClose, IoCloseCircleOutline, IoDownload, IoSend } from "react-icons/io5";
import OffCanvasLoader from "components/loader/OffCanvasLoader";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useAuthValue } from "context/AuthContext";
import { STATUSES } from "constants";
import moment from "moment";
import SpinnerForButton from "components/loader/SpinnerForButton";
import { dispatchAppEvent, reloadIfUnathorized } from "utils/helpers";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InputUpload from "components/InputUpload";
import { useSelector } from "react-redux";
import { selectUploads } from "store/selectors";
import { toast } from "react-toastify";

function RevisionUpload({
    revision,
    handleRefreshView,
    viewData,
    isLoading, setIsLoading,
    handleClose
}) {

    const [isSwitchToUpload, setIsSwitchToUpload] = useState(false);
    const gkey = `render_revision_${revision.id}`;
    const [note, setNote] = useState(revision.editor_note ?? '');
    const uploads = useSelector(selectUploads)[gkey];

    const [ upload ] = Object.values(uploads ?? {});

    const handleSave = () => {
        setIsLoading(true);
        axios.post(`render-views/${viewData.id}/${revision.id}/upload`, {
            ...(upload && { path: upload.path }),
            note
        })
        .then((res) => handleRefreshView(revision.id, res.data))
        .then(() => handleClose());
    }

    return <>
            {revision.image && !isSwitchToUpload ?
                <div className="rounded-top bg-github overflow-hidden position-relative" style={{ backgroundImage: `url(${revision.image.url})`, backgroundSize: 'cover', backgroundPosition: 'center center'}}>
                    <div style={{backdropFilter: 'blur(10px)'}}>
                        <div>
                            {revision.version > 0 && <span className="text-12 bg-gray-dark position-absolute top-0 end-0 m-1 rounded fw-600 px-2">
                                REV {revision.version}
                            </span>}
                            <span className={`text-12 top-0 position-absolute start-0 m-1 rounded fw-600 px-2 bg-warning`}>
                                DA INVIARE
                            </span>
                            <a target="_blank" href={revision.image.url}>
                                <span className={`text-12 bottom-0 position-absolute end-0 m-1 rounded fw-600 px-2 bg-gray-dark cursor-pointer`}>
                                    <IoDownload className="me-05 vertical-align-n1"/> SCARICA
                                </span>
                            </a>
                        </div>
                        <img 
                            src={revision.image.url} style={{ maxWidth: '100%', maxHeight: '250px', display: 'block', margin: 'auto', objectFit: 'contain' }}
                        />
                    </div>
                </div> :
                <InputUpload 
                    gkey={gkey}
                    accept="image/*"
                    limit={1}
                    required
                />
            }
        <div className="mb-2 text-16">

            <div className="form-group boxed p-0 mt-2">
                <div className="input-wrapper h-100">
                    <textarea 
                        rows="6" 
                        className="form-control lh-20 h-100" 
                        name="note" 
                        placeholder="Note per il cliente"
                        onChange={e => setNote(e.target.value)}
                        value={note} 
                    />
                </div>
            </div>

            <Button
                className={`btn-block mt-2 btn btn-primary text-uppercase ${((!revision.image && !(upload && upload.completed)) || isLoading) ? 'disabled' : ''}`}
                type="button"
                onClick={handleSave}
            ><SpinnerForButton show={isLoading} />SALVA</Button>

            {revision.image && <>
                <div className='text-center text-12 mt-1'><i>oppure</i></div>
                <div 
                    className='text-active text-center cursor-pointer'
                    onClick={() => setIsSwitchToUpload(!isSwitchToUpload)}
                >
                    {isSwitchToUpload ? 'Annulla sostituzione' : 'Sostituisci immagine'}
                </div>
            </>}
        </div>
    </>
}

function RevisionSlide({
    revision,
    handleRefreshView,
    viewData,
    handleClose,
    index,
    isLoading,
    setIsLoading,
    sliderRef
}) {

    const commentsRef = useRef(null);
    const [isConfirmRevision, setIsConfirmRevision] = useState(false);
    const [commentText, setCommentText] = useState('');
    const { user } = useAuthValue();
    const role = user.is_agent ? 'agent' : 'editor';

    const statusData = STATUSES[revision.status].is_global ? STATUSES[revision.status].global : STATUSES[revision.status][role];
    const userCanComment = (!revision.comments[revision.comments.length - 1]?.[role] || role === 'editor');

    useEffect(() => {
        setCommentText('');
    }, [isConfirmRevision]);
    
    const handleSendComment = (e, revision_id) => {
        e.preventDefault();
        setIsLoading(true);
        axios.post(`render-views/${viewData.id}/${revision_id}/comments`, {
            text: commentText
        })
        .then((res) => handleRefreshView(revision_id, res.data))
        .catch(reloadIfUnathorized)
        .then(() => {
            setCommentText('');
            setTimeout(() => {
                scrollDownComment();
            }, 200);
        })
    };

    const handleConfirmRevision = (revision_id) => {
        setIsLoading(true);
        axios.post(`render-views/${viewData.id}/${revision_id}/approve`)
        .then((res) => handleRefreshView(revision_id, res.data))
        .catch(reloadIfUnathorized)
        .then(handleClose)
    }

    const handleNewRevision = () => {
        axios.post(`render-views/${viewData.id}/revision`)
        .then((res) => handleRefreshView(res.data.id, res.data))
        .then(() => sliderRef.current.slickNext())
        .catch(reloadIfUnathorized)
    }

    const scrollDownComment = () => {
        commentsRef.current.scrollTop = commentsRef.current.scrollHeight;
    }

    useEffect(() => {
        if (!commentsRef.current) return;
        scrollDownComment();
    }, [commentsRef]);


    return <>
        <div className="rounded-top bg-github overflow-hidden position-relative" style={{ backgroundImage: `url(${revision.image.url})`, backgroundSize: 'cover', backgroundPosition: 'center center'}}>
            <div style={{backdropFilter: 'blur(10px)'}}>
                <div>
                    {revision.version > 0 && <span className="text-12 bg-gray-dark position-absolute top-0 end-0 m-1 rounded fw-600 px-2">
                        REV {revision.version}
                    </span>}
                    <span className={`text-12 top-0 position-absolute start-0 m-1 rounded fw-600 px-2 bg-${statusData.badgeColor}`}>
                        {statusData.badgeLabel}
                    </span>
                    <a target="_blank" href={revision.image.url}>
                        <span className={`text-12 bottom-0 position-absolute end-0 m-1 rounded fw-600 px-2 bg-gray-dark cursor-pointer`}>
                            <IoDownload className="me-05 vertical-align-n1"/> SCARICA
                        </span>
                    </a>
                </div>

                <img src={revision.image.url} style={{ maxWidth: '100%', maxHeight: '250px', display: 'block', margin: 'auto', objectFit: 'contain' }} />
            </div>
        </div>

        <div className="mb-1 text-14">
            <div className='text-gray px-3 py-3 lh-1 bg-gray-dark mb-1 rounded-bottom'>
                {revision.editor_note}
            </div>

            {revision.comments.length > 0 && <div className="listview-title px-0">Commenti a questa vista:</div>}

            {revision.comments.length > 0 && (
                <div className='rounded no-scrollbar' ref={commentsRef} style={{ maxHeight: '20vh', overflowY: 'scroll' }}>
                    {revision.comments.map(comment => (
                        <div className={`message-item px-0 ${comment[role] ? 'user' : ''}`} key={comment.id}>
                            <div className="content">
                                {comment[role] && <div className="title">
                                    {role === 'agent' && comment[role].display_name}
                                </div>}
                                <div className="bubble py-2">
                                    {comment.text}
                                </div>
                                <div className="footer">
                                    {moment(comment.created_at).format('DD/MM/YY - HH:mm')}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            
            {role === 'agent' && revision.status === 'WAITING_CUSTOMER' && (
                isConfirmRevision ? <>
                    <div className="form-group boxed p-0 mt-2">
                    <div className="input-wrapper h-100">
                            <textarea 
                                rows="3" 
                                className="form-control lh-20 h-100" 
                                name="note" 
                                placeholder="Note per questa revisione. Cosa vuoi modificare?"
                                onChange={e => setCommentText(e.target.value)}
                                value={commentText}
                            />
                        </div>
                    </div>

                    <Button
                        className={`btn-block mt-1 btn text-uppercase mt-2 ${(commentText && !isLoading) ? '' : 'disabled'}`}
                        type="button"
                        variant='danger'
                        onClick={(e) => handleSendComment(e, revision.id)}
                    >
                        <SpinnerForButton show={isLoading} />
                        Richiedi una nuova revisione
                    </Button>

                    <div className='text-center text-12 mt-1'><i>oppure</i></div>

                    <div 
                        className='text-active text-center cursor-pointer'
                        onClick={() => setIsConfirmRevision(false)}
                    >Torna alla schermata di approvazione</div>
                </> : <>
                    <Button
                        className={`btn-block mt-1 btn text-uppercase mt-2 ${isLoading ? 'disabled' : ''}`}
                        type="button"
                        variant='success'
                        onClick={() => !isLoading && handleConfirmRevision(revision.id)}
                    >
                        <SpinnerForButton show={isLoading} />
                        {!isLoading &&  <IoCheckmarkCircle/>}Approva</Button>

                    <div className='text-center text-12 mt-1'><i>oppure</i></div>
                    <div 
                        className='text-active text-center cursor-pointer'
                        onClick={() => !isLoading && setIsConfirmRevision(true)}
                    >
                        Scrivici eventuali modifiche
                    </div>
                </>
            )}

            {(viewData.revisions.length - 1) === index && revision.status === 'REJECTED' && <>
                <div className="chatFooter position-relative no-border mt-2 px-0">
                    <form onSubmit={(e) => handleSendComment(e, revision.id)}>
                        <div className="form-group boxed w-100">
                            <div className="input-wrapper">
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Pubblica una risposta..." 
                                    disabled={!userCanComment || isLoading}
                                    value={commentText}
                                    onChange={e => setCommentText(e.target.value)}
                                />
                            </div>
                        </div>
                        <button 
                            type="submit" 
                            className={`btn btn-icon btn-primary rounded ms-2 ${(userCanComment && commentText && !isLoading) ? '' : 'disabled'}`} 
                            style={{ width: '45px' }}
                        >
                            {isLoading ?
                                <SpinnerForButton noMargin show/> : <IoSend />
                            }
                        </button>
                    </form>
                </div>
                {!userCanComment && <div className="text-warning lh-1 text-12 text-start mt-05 mb-2">
                    Attendi la risposta da parte dell'editor prima di poter inviare un nuovo commento.
                </div>}
            </>}
            {role === 'editor' && revision.status === 'REJECTED' && (viewData.revisions.length - 1) === index && <>
                <hr />
                <p className='text-center text-12 mb-0'><i>oppure</i></p>
                <div 
                    className='text-active text-center cursor-pointer'
                    onClick={handleNewRevision}
                >
                    Carica una nuova revisione
                </div>
            </>}
        </div>
    </>
}

export function OffcanvasRenderView({
    renderViewId,
    handleClose,
    orderData,
    onUpdateView
}) {

    let sliderRef = useRef(null);
    const [viewData, setViewData] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!renderViewId) return setViewData();
        axios.get('render-views/' + renderViewId)
        .then(res => {
            setViewData(res.data);
        });
    }, [renderViewId]);

    const handleRefreshView = (revision_id, data) => {
        return new Promise(resolve => {
            const newViewData = {
                ...viewData,
                revisions: viewData.revisions.find(r => r.id === revision_id) ? 
                    viewData.revisions.map(rev => rev.id === revision_id ? data : rev) : 
                    [...viewData.revisions, data]
            };
            setViewData(newViewData);
            onUpdateView(newViewData);
            setIsLoading(false);
            resolve();
        });
    }

    return (<>
        <Offcanvas 
            placement="bottom" 
            className="action-sheet max-width-600" show={!!renderViewId}
            onHide={handleClose}
        >
            <Offcanvas.Body>
                {viewData ? <>
                    <OffCanvasCloseBtn handleClose={handleClose}/>
                    <div className="action-sheet-content p-0 pb-1 overflow-hidden">
                        <div className="listview-title pt-2 fw-700 justify-content-center text-center">
                            <h3 className="mb-0">
                                {viewData.render_ambient.label} - Ambiente {viewData.ambient_index} - Vista {viewData.view_index}
                            </h3>
                        </div>
                        <div className="slider-container render-slider"> 
                            <Slider 
                                initialSlide={viewData.revisions.length - 1}
                                infinite={false}
                                speed={500}
                                slidesToShow={1}
                                slidesToScroll={1}
                                variableWidth={false}
                                arrows={viewData.revisions.length > 1}
                                dots={viewData.revisions.length > 1}
                                ref={slider => {
                                    sliderRef.current = slider;
                                }}
                            >
                                {viewData.revisions.map((revision, index) => {
                                    return (
                                        <div key={revision.id}>
                                            {revision.status === 'WAITING_EDITOR' ? 
                                            <RevisionUpload
                                                {...{
                                                    revision,
                                                    handleRefreshView,
                                                    viewData,
                                                    isLoading, setIsLoading,
                                                    handleClose
                                                }}
                                            /> : 
                                            <RevisionSlide
                                                key={revision.id}
                                                {...{
                                                    revision,
                                                    handleRefreshView,
                                                    viewData,
                                                    handleClose,
                                                    index,
                                                    isLoading, setIsLoading,
                                                    sliderRef,
                                                }}
                                            />}
                                        </div>
                                    )
                                })}
                            </Slider>
                        </div>
                    </div>
                </> : <OffCanvasLoader />}
            </Offcanvas.Body>
        </Offcanvas>
    </>);
}