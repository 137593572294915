import axios from "axios";
import useEditAppointment from "hooks/useEditAppointment";
import useEventListener from "hooks/useEventListener";
import moment from "moment";
import { useEffect, useState } from "react";
import { imageURLToBase64 } from 'utils/helpers';

export default function useOffCanvasViewAppointment(props) {

    const { 
        appointmentId,
        handleClose
    } = props;

    const [appointmentData, setAppointmenData] = useState();
    const [headerImage, setHeaderImage] = useState();
    const [needSave, setNeedSave] = useState(false);
    const [address, setAddress] = useState('');

    const { 
        checkUpdateAppointment,
        checkDeleteAppointment
    } = useEditAppointment();

    const handleCloseOffCanvas = () => {
        setAppointmenData();
        setHeaderImage();
        setAddress('');
        setNeedSave(false);
    }
    
    const dispatchConfirmDelete = () => {
        checkDeleteAppointment(appointmentData.id);
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'date') {
            if (!value) return;
            setAppointmenData({
                ...appointmentData,
                estimated_start_at: `${value} ${moment(appointmentData.estimated_start_at).format('HH:mm')}`,
                estimated_end_at: `${value} ${moment(appointmentData.estimated_end_at).format('HH:mm')}`,
            });
            return setNeedSave(true);
        }
        if (name === 'photographer') {
            setAppointmenData({
                ...appointmentData,
                photographer_id: value,
            });
            return setNeedSave(true);
        }
        const field = ['start', 'end'].includes(name) ? `time_${name}` : name;
        const updateObject = {
            ...appointmentData,
            [field]: value,
        };
        if (name === 'start') {
            const [hour, minute] = value.split(':');
            const newStart = moment(appointmentData.estimated_start_at).hour(hour).minute(minute);
            updateObject['time_end'] = newStart.add(appointmentData.duration, 'minutes').format('HH:mm');
        }
        setAppointmenData(updateObject);
        setNeedSave(true);
    }

    const handleUpdate = () => {
        const appointmentDate = moment(appointmentData.estimated_start_at);
        const [start_hour, start_minute] = appointmentData.time_start.split(':');
        const [end_hour, end_minute] = appointmentData.time_end.split(':');
        checkUpdateAppointment({
            ...appointmentData,
            photographer_id: +(appointmentData.photographer_id),
            estimated_start_at: appointmentDate.hour(start_hour).minute(start_minute).format('YYYY-MM-DD HH:mm'),
            estimated_end_at: appointmentDate.clone().hour(end_hour).minute(end_minute).format('YYYY-MM-DD HH:mm')
        })
    }

    const onUpdateAppointment = (newAppointmentData) => {
        if (!appointmentData) return;
        setAppointmenData({
            ...appointmentData,
            ...newAppointmentData
        });
        setNeedSave(false);
        handleClose();
    }

    const onDeleteAppointment = () => handleClose();

    const handleUnlockAppointment = () => {
        setAppointmenData({
            ...appointmentData,
            is_lock: false
        });
    }

    useEventListener({
        'appointment_updated': (e) => onUpdateAppointment(e.detail.appointment),
        'appointment_deleted': (e) => onDeleteAppointment(e.detail.appointment),
    }, [appointmentData]);

    useEffect(() => {
        if (!appointmentId) return handleCloseOffCanvas();
        axios.get(`/appointments/${appointmentId}`)
        .then(res => {
            (res.data.is_manual ? Promise.resolve() : imageURLToBase64(res.data.building.image_url))
            .then(image => {
                image && setHeaderImage(image);
            })
            .catch(error => console.log(error))
            .finally(() => {
                setAppointmenData({
                    ...res.data,
                    time_start: moment(res.data.estimated_start_at).format('HH:mm'),
                    time_end: moment(res.data.estimated_end_at).format('HH:mm')
                })
                res.data.coords && setAddress(res.data.address.full);
            });
        });
    }, [appointmentId]);

    const handleRetriveAddress = (autocompleteObj) => {
        setAppointmenData(prevData => ({
            ...prevData,
            address: autocompleteObj.values,
            coords: autocompleteObj.coords,
        }));
    };

    const handleResetAddress = () => {
        setAddress('');
        setAppointmenData({
            ...appointmentData,
            address: null,
            coords: null
        });
    }

    return {
        appointmentData,
        headerImage,
        dispatchConfirmDelete,
        handleChange,
        needSave,
        handleUpdate,
        address, setAddress,
        handleRetriveAddress,
        handleResetAddress,
        handleUnlockAppointment
    }
}