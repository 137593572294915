//import useReservationCheckout  from "./useReservationCheckout";
import Capsule from 'layout/Capsule';
import Accordion from 'react-bootstrap/Accordion';

//Assets
import amexImage from './img/amex.svg';
import visaImage from './img/visa.svg';
import mastercardImage from './img/mastercard.svg';
import { IoAlert, IoCalendarOutline, IoCardOutline, IoCashOutline, IoHomeOutline, IoWalletOutline } from 'react-icons/io5';

//Utils
import { capitalizeFirstLetter, numb } from 'utils/helpers';
import { Alert, Button, FormCheck } from 'react-bootstrap';
import { useParams } from 'react-router';
import { useAuthValue } from 'context/AuthContext';
import useOffCanvasReducer from 'hooks/useOffCanvasReducer';
import OffCanvasAddFunds from 'components/offcanvas/OffCanvasAddFunds';
import SpinnerForButton from 'components/loader/SpinnerForButton';
import Checkout from 'components/Checkout';


function TemplateProduct(props) {

    const { product } = props;

    return (
        <li className={product.indent ? 'ps-3' : ''}>
            {product.indent ? '+' : ''} {product.description}
            <span className="text-muted" style={{whiteSpace: "nowrap"}}>
                {product.time ? `${product.time} min` : ''}
                <span className="right-price">{product.price ? numb(product.price) : 0}€</span>
            </span>
        </li>
    );
}

export default function ReservationCheckout(props) {

    const {
        buildingData,
        cartItems,
        selectedTimeSlot,
        isInProgress,
        isCartInProgress,
        completeCheckout,
        checkoutData,
        handleChangeCheckoutData,
        couponData,
        applyCoupon
    } = props;


    const { user } = useAuthValue();
    const { credit = null, subscription = null} = buildingData?.organization ?? {};
    const travel_paid = (selectedTimeSlot?.travel_paid?.km_refund ?? 0) + (selectedTimeSlot?.travel_paid?.toll ?? 0);
    const order_total = cartItems.price + travel_paid;
    const order_total_vati = order_total * 1.22;
    const order_discount = order_total * (subscription?.discount_percentage || 0) / 100;
    const real_cart_total = order_total - (subscription ? order_discount : 0);
    const real_cart_total_vi = real_cart_total * 1.22;

    const [showOffCanvasAddFunds, dispatchShowOffCanvasAddFunds] = useOffCanvasReducer();
    const { appointmentId } = useParams();

    return (
        <>
            <Capsule className='extra-header-active'>
                <div className="row mt-2">

<div className='col-12 col-md-5 pe-md-0'>

                <div className="section mb-2">
                    <div className="card">

                        <ul className="listview image-listview rounded">
                            <li>
                                <div className="item">
                                    <div className="icon-box bg-primary">
                                        <IoHomeOutline />
                                    </div>
                                    <div>
                                        <div className="text-14">{buildingData.address.full}</div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="item">
                                    <div className="icon-box bg-primary">
                                        <IoCalendarOutline />
                                    </div>
                                    <div className="text-14">
                                        {capitalizeFirstLetter(selectedTimeSlot.start.format('dddd D MMMM YYYY - HH:mm'))}
                                        {' -> '}
                                        {selectedTimeSlot.start.clone().add(cartItems.time, 'minutes').format('HH:mm')}
                                    </div>
                                </div>
                            </li>
                            {buildingData.resident_type === 'NOBODY' ? <li>
                                <div className="item">
                                    <div className="icon-box bg-warning">
                                        <IoAlert />
                                    </div>
                                    <div className="text-14 text-warning lh-1">
                                        Essendo che l'immobile non risulta essere abitato sarà necessario recarsi sul posto per aprire l'abitazione.
                                    </div>
                                </div>
                            </li> : <li>
                                <div className="item">
                                    <div className="icon-box bg-warning">
                                        <IoAlert />
                                    </div>
                                    <div className="text-14 w-100">
                                        Consulente sul posto?
                                    </div>
                                    <FormCheck
                                        id="agent_on_site"
                                        name="agent_on_site"
                                        type="switch"
                                        label=""
                                        checked={checkoutData.agent_on_site}
                                        onChange={handleChangeCheckoutData}
                                        className='ms-1'
                                    />
                                </div>
                            </li>}
                        </ul>
                    </div>
                </div>

                {!appointmentId && <div className="section mt-2 mb-3">
                    <div className="section-title">
                        <h4 className="card-subtitle text-white text-12 mb-0">Riepilogo</h4>
                    </div>
                    <div className="card">
                        <ul className="listview simple-listview text-14 rounded">
                            {cartItems.products.sort((a, b) => {
                                if (a.type !== b.type) return a.type === 'PRIMARY' ? -1 : 1;
                                return a.order_by - b.order_by;
                            }).map(product => {
                                const return_products = [<TemplateProduct key={product.id} product={{...product, indent: product.parent_id}} />];
                                if (product.is_priority) {
                                    return_products.push(
                                        <TemplateProduct
                                            key={product.id + 'priority'}
                                            product={{
                                                description: product.priority_label,
                                                price: product.priority_price,
                                                indent: true
                                            }}
                                        />
                                    );
                                }
                                return return_products;
                            }).flat()}
                            {travel_paid > 0 && <TemplateProduct
                                key="travel_paid"
                                product={{
                                    description: 'Costo di trasferta',
                                    price: travel_paid,
                                    indent: false
                                }}
                            />}
                            <li className="bg-gray-dark fw-600">
                                Subtotale
                                <span className="active">
                                    <span className="fw-600 right-price">{numb(order_total)}€</span>
                                </span>
                            </li>
                            <li className="bg-gray-dark fw-600">
                                Totale (IVA inclusa)
                                <span className="active">
                                    {cartItems.time} min
                                    <span className="fw-600 right-price ms-2">
                                        {numb(order_total_vati)}€
                                    </span>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>}

                </div>
                <div className='col-12 col-md-7 ps-md-0'>

                <div className='section'>
                    <div className="form-group boxed custom py-0">
                        <div className="input-wrapper">
                            <textarea
                                className="form-control"
                                id="note"
                                name="note"
                                value={checkoutData.note}
                                onChange={handleChangeCheckoutData}
                                placeholder="Note appuntamento"
                            />
                        </div>
                    </div>
                </div>
                

                {(appointmentId || order_total_vati === 0) ? (
                    <button
                        type="button"
                        className={`btn btn-block btn-success mt-2 ${(isInProgress || isCartInProgress) ? 'disabled' : ''}`}
                        onClick={completeCheckout}
                    >
                        <SpinnerForButton show={isCartInProgress}/>
                        {`${appointmentId ? 'Modifica' : 'Conferma'} prenotazione`}
                    </button>
                ) : (
                    <Checkout 
                    buildingData={buildingData}
                    cartItems={cartItems}
                    isInProgress={isInProgress}
                    isCartInProgress={isCartInProgress}
                    completeCheckout={completeCheckout}
                    couponData={couponData}
                    applyCoupon={applyCoupon}
                />
                )}

                </div>
                </div>

            </Capsule>
            {!user.is_admin && <OffCanvasAddFunds 
                show={showOffCanvasAddFunds}
                handleClose={() => dispatchShowOffCanvasAddFunds('CLOSE')}
            />}
        </>);
}
