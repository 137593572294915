//Components
import Alert from 'react-bootstrap/Alert';
import Offcanvas from 'react-bootstrap/Offcanvas';
import OffCanvasCloseBtn from 'components/offcanvas/OffCanvasCloseBtn';
import OffCanvasBackBtn from 'components/offcanvas/OffCanvasBackBtn';
import Button from 'react-bootstrap/Button';
import {
    IoSearchOutline,
    IoLocationOutline,
    IoCloseOutline,
    IoInformationCircleOutline,
    IoChevronForwardOutline,
    IoCheckmarkCircle,
    IoAlertCircle,
    IoWarning,
} from 'react-icons/io5';
import { AddressMinimap } from '@mapbox/search-js-react';

//Logic
import {
    useOffcanvasAddBuilding,
    useStepAddress,
    useStepMap,
    useStepInfo,
    useStepInfoResident
} from './useOffcanvasAddBuilding';

//Assets
import svgSearchAddress from './img/search-address.svg';
import SpinnerForButton from 'components/loader/SpinnerForButton';

import { capitalizeFirstLetter, mapBoxFeatureFormat } from 'utils/helpers';

function StepAddress(props) {

    const {
        addressRef,
        addressValue,
        handleAddressChange,
        results,
        handleSelectPlace,
        isInProgress
    } = useStepAddress(props);

    return (<>
        <div className="form-group boxed custom">
            <div className="input-wrapper">
                <IoSearchOutline className="input-icon-search" />
                <input 
                    type="text"
                    className="form-control"
                    id="search_address"
                    placeholder="Ricerca indirizzo..."
                    ref={addressRef}
                    onChange={handleAddressChange}
                    value={addressValue}
                />
            </div>
        </div>

        <ul className="listview image-listview py-1 no-border-bottom mt-2">
            {results === null && new Array(4).fill(null).map((_, index) => {
                return <li key={index}>
                <a className="item ps-0">
                    <div className="item px-0 py-0">
                        <div className="icon-box me-1">
                            <IoLocationOutline />
                        </div>
                        <div className="in">
                            <div>
                                <span className="placeholder" style={{ width: '100px'}}></span>
                                <span className="d-block text-muted">
                                    <span className="placeholder" style={{ width: '230px'}}></span>
                                </span>
                            </div>
                        </div>
                    </div>
                </a>
            </li>
            })}
            {results && results.length > 0 && results.map((place, index) => {
                return <li key={index} onClick={() => !isInProgress && handleSelectPlace(place)}>
                    <a className="item ps-0">
                        <div className="item px-0 py-0">
                            <div className="icon-box me-1">
                                {place.mapbox_id === isInProgress ? <SpinnerForButton show /> : <IoLocationOutline />}
                            </div>
                            <div className="in">
                                <div>
                                    <span>{place.name}</span>
                                    <span className="d-block text-muted">{place.place_formatted}</span>
                                </div>
                            </div>
                        </div>
                    </a>
                </li>
            })}
        </ul>
        {results?.length === 0 && <div className="alert alert-outline-primary notice-time-variation no-results">
            <IoAlertCircle className="vertical-align-n2 me-1"/>
            Nessun risultato trovato. Assicurati di aver inserito correttamente l'indirizzo <span className='text-warning'>completo di numero civico</span>.
        </div>}
        {typeof results === 'undefined' &&<div className='p-4'>
            <img src={svgSearchAddress} className="d-block m-auto" height="242" width="229"/>
        </div>}
       </>);
}

function StepMap(props) {

    const {
        stepAddressData,
        handleClose,
        addressError
    } = props;


    const {
        handleNextStep,
        handlePreviusStep,
        showMap,
        changeLocation,
        isEditMap,
        handleIsEditMap,
    } = useStepMap(props);
    

    return (
        <>
            <div 
                className="map mapbox-iframe"
                onClick={handleIsEditMap}
                style={{ display: showMap ? 'block' : 'none'}}
            >
                {stepAddressData && <AddressMinimap 
                    accessToken={process.env.REACT_APP_MAPBOX_API}
                    show={showMap}
                    feature={stepAddressData}
                    canAdjustMarker={!addressError}
                    keepMarkerCentered={window.innerWidth < 400}
                    defaultMapStyle={['mapbox','dark-v10']}
                    {...{
                        ...!addressError && {
                            adjustBtnText: "Modifica posizione",
                            cancelBtnText: "Annulla",
                            saveBtnText: "Conferma"
                        }
                    }}
                    theme={{
                        variables: {
                            fontFamily: 'inter',
                            colorPrimary: "#135cfa",
                            spacing: "0"
                        }
                    }}
                    footer={false}
                    onSaveMarkerLocation={changeLocation}
                />}
            </div>
            <a className="offcanvas-close close-btn-map" onClick={handleClose}>
                <IoCloseOutline className="iconCloseMapBoxBuilding"/>
            </a>

            <div className="px-3 mb-1 py-2 pb-0">
                <div className="notice_info">
                    <IoInformationCircleOutline className='vertical-align-n2 me-05'/>
                    Verifica che la posizione dell'immobile sia corretta, in alternativa aggiusta il segnaposto.
                </div>

                <div className="form-group boxed custom">
                    <div className="input-wrapper">
                        <input 
                            type="text" 
                            className="form-control"
                            defaultValue={stepAddressData ? mapBoxFeatureFormat(stepAddressData).address.full : ''}
                            readOnly
                            onClick={handlePreviusStep}
                        />
                    </div>
                </div>
                {addressError && <Alert variant="danger" className="mt-1 mb-2">
                    Ops, al momento <b>non abbiamo fotografi nella tua zona</b>. Siamo comunque disponibili a trasferte in tutta italia, ti invitiamo a contattarci a <a href="mailto:info@photodaf.com"><b>info@photodaf.com</b></a> per richiedere un preventivo personalizzato.
                </Alert>}
                {!isEditMap &&<div className="form-group basic">
                    <Button
                        className={`btn-block ${addressError ? 'disabled' : ''}`}
                        type="button"
                        onClick={handleNextStep}
                    >Continua<IoChevronForwardOutline className="next-icon-btn" /></Button>
                </div>}
            </div>
        </>
    );
 }

export function StepInfo(props) {

        const { 
            buildingTypes,
            stepInfo,
            isEdit,
            isInProgress
         } = props;

        const { 
            handleChange,
            handleChangeBuildingType,
            handleContinue,
            error,
            organizationSelected
        } = useStepInfo(props);

        const canContinue = 
    !!stepInfo.reference.trim() && // Verifica che il campo 'reference' non sia vuoto
    (organizationSelected?.organization_type?.ask_owner_detail ? ( // Se l'organizzazione richiede i dettagli del proprietario
        !!stepInfo.owner_first_name.trim() && // Verifica che il campo 'owner_first_name' non sia vuoto
        !!stepInfo.owner_last_name.trim() // Verifica che il campo 'owner_last_name' non sia vuoto
    ) : true) && // Altrimenti, passa il controllo
    !!stepInfo.buildingType && // Verifica che il campo 'buildingType' non sia vuoto
    (stepInfo.buildingType && stepInfo.buildingType.show_floor ? !!stepInfo?.floor?.trim() : true) && // Se 'buildingType' richiede il piano, verifica che il campo 'floor' non sia vuoto
    !!+stepInfo.sqm && // Verifica che il campo 'sqm' (metri quadrati) sia un numero positivo
    (stepInfo.buildingType && stepInfo.buildingType.show_garden ? !!+stepInfo.garden_sqm : true); // Se 'buildingType' richiede la metratura del giardino, verifica che il campo 'garden_sqm' sia un numero positivo

        return (<>
            <div className="form-group boxed custom">
                <div className="input-wrapper">
                    <label className="form-label" htmlFor="reference">Riferimento immobile*</label>
                    <input 
                        type="text"
                        className="form-control" 
                        id="reference" 
                        name="reference"
                        onChange={handleChange}
                        value={stepInfo.reference}
                        maxLength={30}
                        autoComplete="off"
                    />
                </div>
            </div>

            {!!organizationSelected?.organization_type?.ask_owner_detail && (
                <>
                    <div className="row">
                        <div className="col ps-1">
                            <div className="form-group boxed custom">
                                <div className="input-wrapper">
                                    <label className="form-label label-bordered" htmlFor="owner_first_name">Nome proprietario*</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="owner_first_name" 
                                        name="owner_first_name"
                                        value={capitalizeFirstLetter(stepInfo.owner_first_name)}
                                        onChange={handleChange}
                                        maxLength={50}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col pe-1">
                            <div className="form-group boxed custom">
                                <div className="input-wrapper not-empty">
                                    <label className="form-label label-bordered" htmlFor="owner_last_name">Cognome proprietario*</label>
                                    <input
                                        className="form-control" 
                                        type="text"
                                        id="owner_last_name" 
                                        name="owner_last_name"
                                        value={capitalizeFirstLetter(stepInfo.owner_last_name)}
                                        onChange={handleChange}
                                        maxLength={50}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}

            <div className="form-group boxed custom">
                <div className="input-wrapper">
                    <label className="form-label" htmlFor="building_type_id">Tipologia immobile*</label>
                    <select
                        className="form-control form-select"
                        id="building_type_id"
                        name="building_type_id"
                        value={stepInfo.buildingType ? stepInfo.buildingType.id : 0}
                        onChange={handleChangeBuildingType}
                    >
                        <option value="0" disabled>Seleziona tipologia</option>
                        {buildingTypes && buildingTypes.map(buildingType => {
                            return <option key={buildingType.id} value={buildingType.id}>{buildingType.description}</option>
                        })}
                    </select>
                </div>
            </div>

            {(stepInfo.buildingType && stepInfo.buildingType.show_floor) ? <div className="form-group boxed custom">
                <div className="input-wrapper">
                    <label className="form-label" htmlFor="floor">Piano*</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        autoComplete="off"
                        id="floor"
                        name="floor"
                        maxLength={10}
                        value={stepInfo.floor || ''}
                        onChange={handleChange}
                    />
                </div>
            </div> : ''}

            <label className="form-label mb-1 slider-label" htmlFor="sqm">
                <b>*Metratura immobile:</b> <span>{stepInfo.sqm}</span>m²
            </label>

            <div className="w-100 mb-2">
                <input 
                    type="range" 
                    min="0" 
                    max="500" 
                    step="10" 
                    className="slider" 
                    id="sqm"
                    name="sqm"
                    value={stepInfo.sqm}
                    onChange={handleChange}
                />
            </div>

            {(stepInfo.buildingType && stepInfo.buildingType.show_garden) ? <>
                <label className="form-label mt-1 mb-1 slider-label">
                    <b>*Metratura giardino:</b> <span id="metratura-giardino-num">{stepInfo.garden_sqm}</span>m²
                </label>

                <div className="w-100 mb-2">
                    <input
                        type="range" 
                        min="0" 
                        max="1000" 
                        step="50" 
                        className="slider" 
                        id="garden_sqm"
                        name="garden_sqm"
                        value={stepInfo.garden_sqm}
                        onChange={handleChange}
                    />
                </div>
            </> : ''}

            <div className="form-group basic">
                {error && (
                    <Alert variant="danger" className="mb-3">
                        {error}
                    </Alert>
                )}
                <Button
                    className={`btn-block ${!canContinue || isInProgress ? 'disabled' : '' }`}
                    type="button"
                    onClick={handleContinue}
                >
                    <SpinnerForButton show={isInProgress} />
                    {isEdit ? 'Salva' : <>
                        Continua
                        <IoChevronForwardOutline className='next-icon-btn'/>
                    </>}
                </Button>
            </div>
        </>
    );
}

export function StepInfoResident(props) {

    const {
        isReservation,
        stepInfoResident,
        isInProgress,
        isEdit,
        genericError
    } = props;

    const {
        error,
        handleChange,
        handleSubmit,
        organizationSelected
    } = useStepInfoResident(props);

    const canContinue = !!organizationSelected?.organization_type?.ask_owner_detail ? (
        !!stepInfoResident.resident_type &&
        (
            stepInfoResident.resident_type !== 'NOBODY' ? (
                !!stepInfoResident.resident_phone.trim() && 
                !!stepInfoResident.resident_email.trim() &&
                !!stepInfoResident.doorbell_name
            ) : true
        )
    ) : !!stepInfoResident.doorbell_name;

    return (<>
        {!!organizationSelected?.organization_type?.ask_owner_detail && (
            <div className="form-group boxed custom">
                <div className="input-wrapper">
                    <label className="form-label" htmlFor="resident_type">*Da chi è abitato?</label>
                    <select 
                        className="form-control form-select"
                        id="resident_type"
                        name="resident_type"
                        value={stepInfoResident.resident_type}
                        onChange={handleChange}
                    >
                        <option value="" disabled>Seleziona scelta</option>
                        <option value="OWNER">Proprietario</option>
                        <option value="TENANT">Inquilino</option>
                        <option value="NOBODY">Nessuno</option>
                    </select>
                </div>
            </div>
        )}

        <div className="form-group boxed custom">
            <div className="input-wrapper">
                <label className="form-label" htmlFor="doorbell_name">{stepInfoResident.resident_type !== 'NOBODY' ? '*' : ''}Nominativo sul campanello</label>
                <input 
                    type="text" 
                    id="doorbell_name"
                    name="doorbell_name"
                    className="form-control"
                    autoComplete="off"
                    maxLength={80}
                    value={capitalizeFirstLetter(stepInfoResident.doorbell_name)}
                    onChange={handleChange}                    
                />
            </div>
        </div>

        {(stepInfoResident.resident_type && stepInfoResident.resident_type !== 'NOBODY') ? <>
            <div className="form-group boxed custom">
                <div className="input-wrapper">
                    <label className="form-label" htmlFor="resident_phone">
                        *Telefono {stepInfoResident.resident_type === 'OWNER' ? 'proprietario' : 'inquilino'}
                    </label>
                    <input 
                        type="text"
                        id="resident_phone"
                        name="resident_phone"
                        className="form-control"
                        autoComplete="off"
                        pattern="[0-9]*"
                        maxLength={15}
                        value={stepInfoResident.resident_phone.replace(/\s/g, '')}
                        onChange={handleChange}                    
                    />
                </div>
            </div>

            <div className="form-group boxed custom">
                <div className="input-wrapper">
                    <label className="form-label" htmlFor="resident_email">
                        *Email {stepInfoResident.resident_type === 'OWNER' ? 'proprietario' : 'inquilino'}
                    </label>
                    <input 
                        type="email" 
                        id="resident_email"
                        name="resident_email"
                        className="form-control"
                        autoComplete="off"
                        value={stepInfoResident.resident_email.replace(/\s/g, '')}
                        onChange={handleChange}                    
                    />
                </div>
            </div>
         </> : ''}

        <div className="form-group basic">
            {stepInfoResident.resident_type === 'NOBODY' && (
                <Alert variant="warning" className="mb-3 text-center">
                    <IoWarning className='text-16'/> In caso di immobile non abitato sarà necessario recarsi sul posto per aprire l'abitazione.
                </Alert>
            )}
            {(error || genericError) && (
                <Alert variant="danger" className="mb-3">
                    {error || genericError}
                </Alert>
            )}
            <Button
                className={`btn-${isReservation || isEdit ? 'primary' : 'success'} ${!canContinue || isInProgress ? 'disabled' : ''} btn-block`}
                type="button"
                onClick={handleSubmit}
            >
                <SpinnerForButton show={isInProgress} />
                {isReservation ? 
                    <>Continua<IoChevronForwardOutline className="next-icon-btn" /></> : 
                    (isEdit ? 'Salva' : 'Aggiungi immobile')
                }
            </Button>
        </div>
    </>);
}

function StepDone(props) {

    const { 
        handleNavigateReservation,
        handleClose
    } = props;

    return (<>
        <div className="section mt-3 text-center">
            <h2>Immobile creato</h2>
            <OffCanvasCloseBtn handleClose={handleClose}/>
        </div>

        <div className="action-sheet-content py-2">
            <div className="modal-icon text-success mt-0">
            <IoCheckmarkCircle style={{ fontSize: '110px'}}/>
            </div>
            <div className="text-center mb-1 mt-2 lh-20">
            L'immobile è stato <b>creato con successo</b>! Da ora sarà disponibile nella lista immobili della tua agenzia
            </div>
            <div className="form-group basic">
            <Button
                className="btn-block btn-primary"
                type="button"
                onClick={handleNavigateReservation}
            >Prenota servizio</Button>
            </div>
        </div>
    </>);
}


export default function OffcanvasAddBuilding(props) {

    const {
        show,
        handleClose,
        isReservation
    } = props;

    const {
        isInProgress,
        step, setStep,
        stepBefore,
        stepAddressData, setStepAddressData,
        stepInfo, setStepInfo,
        stepInfoResident, setStepInfoResident,
        buildingTypes,
        insertBuilding,
        handleNavigateReservation,
        addressError, setAddressError,
        organizationSelected,
        genericError, setGenericError
    } = useOffcanvasAddBuilding(props);

    if (!organizationSelected) return;

    if(step === 'map') return (
        <Offcanvas 
            placement="bottom"
            className="action-sheet"
            show={show}
            onHide={handleClose} 
        >
            <Offcanvas.Body>
                <StepMap 
                    {...{
                        handleClose,
                        stepAddressData, setStepAddressData,
                        nextStep: () => setStep('info'),
                        previusStep: () => setStep('address'),
                        addressError
                    }}
                />
            </Offcanvas.Body>
        </Offcanvas>
    );

    if (step === 'done') return (
        <Offcanvas placement="bottom" className="action-sheet" style={{ maxWidth: '500px' }} show={show} onHide={handleClose}>
            <Offcanvas.Body>
                <StepDone 
                    handleNavigateReservation={handleNavigateReservation}
                    handleClose={handleClose}
                />
            </Offcanvas.Body>
        </Offcanvas>
    );

    return (
        <Offcanvas 
            placement="bottom"
            className="action-sheet placeholder-glow"
            show={show}
            onHide={handleClose}
            backdrop="static"
        >
            <Offcanvas.Body>
                {!isInProgress && <OffCanvasCloseBtn handleClose={() => {setGenericError(); handleClose();}}/>}
                {step !== 'address' && !isInProgress && <OffCanvasBackBtn handleBack={()=> {setGenericError(); stepBefore();}} /> }
                <div className="section mt-3 text-center">
                    <h3>{isReservation ? 'Nuova prenotazione' : 'Aggiungi immobile'}</h3>
                </div>
                <div className="action-sheet-content py-1">
                    {step === 'address' && <StepAddress 
                    {...{
                        stepAddressData, setStepAddressData,
                        nextStep: () => setStep('map'),
                        setAddressError,
                        organizationSelected
                    }}
                    /> }
                    {step === 'info' && <StepInfo 
                        {...{
                            nextStep: () => setStep('info-resident'),
                            previusStep: () => setStep('map'),
                            stepInfo, setStepInfo,
                            buildingTypes,
                            organizationSelected
                        }}
                    /> }
                    {step === 'info-resident' && <StepInfoResident 
                        {...{
                            isInProgress,
                            isReservation,
                            onFormComplete: insertBuilding,
                            stepInfo,
                            stepInfoResident, setStepInfoResident,
                            organizationSelected,
                            genericError
                        }}
                    /> }
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    );

}