import axios from "axios";
import { signOut } from "firebase/auth";
import { auth } from './firebase-instance';
import heic2any from 'heic2any';
import { toast } from 'react-toastify';
import { UPLOAD_DEFAULT } from "constants";

export function validateEmail(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export function logout() {
  signOut(auth);
}

export function loadLaravelUser() {
  return new Promise(async resolve => {
    const me = (await axios.get('/user?me')).data;
    if (me.is_agent) {
      if (me.agent.organizations.length > 0) {
        let organization_selected_id = localStorage.organization_selected_id;
        if (!organization_selected_id || !me.agent.organizations.find(o => o.id == organization_selected_id)) {
          organization_selected_id = me.agent.organizations[0].id;
        }
        for(let i=0; i < me.agent.organizations.length; i++) {
          if (+me.agent.organizations[i].id === +organization_selected_id) {
            organization_selected_id = me.agent.organizations[i].id
            me.agent.organizations[i].is_selected = true;
            break;
          }
        }
        localStorage.setItem('organization_selected_id', organization_selected_id);

        me.agent.getSelectedOrganization = function () {
          
          return this.organizations.find(o => o.is_selected);
        }
      } else {
        me.agent.getSelectedOrganization = () => null;
      }
    }
    resolve(me);
  })
}

export function mapBoxFeatureFormat(feature) {
  const { context } = feature.properties;
  const address = {
    street: feature.properties.name,
    locality: context.place.name,
    postal_code: context.postcode.name,
    state: context.region.name.replace('provincia di', '').trim(),
    state_code: context.region.region_code,
  }
  address.full = `${address.street}, ${address.locality}, ${address.postal_code}, ${address.state} (${address.state_code})`;
  const coords = {
    lat: feature.geometry.coordinates[1],
    lng: feature.geometry.coordinates[0]
  }

  return {
    coords,
    address
  }
}

export function imageURLToBase64(url) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = "anonymous";
    image.onload = function() {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      canvas.width = image.width;
      canvas.height = image.height;

      context.drawImage(image, 0, 0);

      try {
        const base64Data = canvas.toDataURL();
        resolve(base64Data);
      } catch (error) {
        reject(error);
      }
    };

    image.onerror = function() {
      reject('Failed to load image');
    };

    image.src = url;
  });
}

export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function reorderArrayItem(array, prev_index, next_index) {
  const newArray = Array.from(array);
  const [removed] = newArray.splice(prev_index, 1);
  newArray.splice(next_index, 0, removed);
  return newArray;
}

export function escapeFilterValue(value) {
  return (value ?? '')
    .replace(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g, '')
    .toUpperCase();
}

export function arrayFilterValue(value) {
  return (value ?? '').replace(/\s{2,}/g, ' ').split(' ');
}

export function filterValue(search_value, full_string) {
  const filter_text = escapeFilterValue(full_string);
  const filter_values = arrayFilterValue(search_value);
  for(let i=0;i<filter_values.length;i++) {
      if (!filter_text.includes(filter_values[i].toUpperCase())) return false;
  }
  return true;
}

export function deepCopy(array) {
  return JSON.parse(JSON.stringify(array));
}

export function getUrlParam(param, remove = false) {
  const url = new URL(window.location.href);
  const value = url.searchParams.get(param);
  if (remove) {
    removeUrlParameter(param);
  }
  return value;
}

export function removeUrlParameter(parameter) {
  const url = new URL(window.location.href);
  url.searchParams.delete(parameter);
  window.history.replaceState({}, document.title, url.href);
  return true;
}

export function debounce(func, timeout = 500){
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

export function isMobile() {
  return window.innerWidth < 992;
}

export function dispatchAppEvent(name, data = {}, dispatcher = document) {
  dispatcher.dispatchEvent(new CustomEvent(name, { detail: data }));
}

export function textToColor(str) {
  let hash = 0;
  str.split('').forEach(char => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash)
  })
  let colour = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff
    colour += value.toString(16).padStart(2, '0')
  }
  return colour
}

export function stripeBalance(number, format = false) {
  const result = (number * -1) / 100;
  if (!format) return result;
  return `${result > 0 ? '+' : ''}${numb(result)}€`;
}

export function numb(number) {
  return Number(number).toLocaleString('it', { 
    minimumFractionDigits: 2,
    maximumFractionDigits: 2 
  });
}

export function isIOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export function getLocalStorage(key, initialValue = null) {
  try {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue;
  } catch (error) {
    return initialValue;
  }
}

export function addressUrl(coords) {
  const default_navigator = getLocalStorage('default_navigator');
  if (default_navigator) {
    if (default_navigator === 'waze') return `https://waze.com/ul?ll=${coords.lat},${coords.lng}&navigate=yes`;
    if (default_navigator === 'google') return `https://www.google.com/maps/dir/?api=1&destination=${coords.lat},${coords.lng}`;
    if (default_navigator === 'apple') return `http://maps.apple.com/?q=${coords.lat},${coords.lng}}`;
  }
  if (isIOS()) return `http://maps.apple.com/?q=${coords.lat},${coords.lng}}`;
  return `https://maps.google.com/?q=${coords.lat},${coords.lng}`;
}

export function openUrl(url) {
  if (!isIOS()) return window.open(url);
  var link = document.createElementNS("http://www.w3.org/1999/xhtml", "a");
  link.href = url;
  link.target = '_blank';
  var event = new MouseEvent('click', {
      'view': window,
      'bubbles': false,
      'cancelable': true
  });
  link.dispatchEvent(event);
}

export function convertIfHEIC(file) {
  return new Promise(resolve => {
    if (!file.type.toLowerCase().includes('heic')) return resolve(file);
    const img_name = file.name.substr(0, file.name.lastIndexOf('.'));
    heic2any({
      blob: file,
      toType: "image/jpeg",
    })
    .then(resultBlob => {
      resolve(
        new File(
          [resultBlob],
          `${img_name}.jpg`
        )
      )
    })
  });
}

export function mapRole(role) {
  return ({
    'accountant': 'No statistiche',
    'social_manager': 'Social manager',
    'agent': 'Agente',
    'admin': 'Amministratore',
    'system': 'Amministrazione di sistema',
    'photographer': 'Fotografo',
    'editor': 'Postproduttore',
  })[role];
}

export function mapRoles(roles) {
  return roles.map(r => mapRole(r));
}

export function copyToClipboard(text, message = null) {
  navigator.clipboard.writeText(text);
  toast.success(message ? message : 'Testo copiato negli appunti');
}

export function weekDayName(day) {
  return ({
    0: 'Domenica',
    1: 'Lunedì',
    2: 'Martedì',
    3: 'Mercoledì',
    4: 'Giovedì',
    5: 'Venerdì',
    6: 'Sabato'
  })[day];
}

export function createUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
  .replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, 
          v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
  });
}

export function getFileExtension(filename) {
  // Verifica che il filename sia definito e non vuoto
  if (!filename || typeof filename !== 'string') {
    return null;
  }

  // Dividi il filename per ogni punto (.)
  var parts = filename.split('.');

  // Se c'è solo un elemento o l'ultimo elemento è vuoto, non c'è un'estensione
  if (parts.length === 1 || (parts.length === 2 && parts[0] === '')) {
    return null;
  }

  // Restituisce l'ultimo elemento dell'array, che dovrebbe essere l'estensione
  return parts.pop().toLowerCase(); // Converte in minuscolo per standardizzare
}


export function objectFromGooglePlace(place) {
  const object = {
    street_address: place.formatted_address,
    google_place_id: place.place_id,
    geometry: [
      place.geometry.location.lng(),
      place.geometry.location.lat()
    ]
  };
  place.address_components.forEach(ac => {
    if (ac.types.includes('street_number'))
      return object.street_number = ac.long_name;
    if (ac.types.includes('route'))
      return object.route = ac.long_name;
    if (ac.types.includes('locality'))
      return object.locality = ac.long_name;
    if (ac.types.includes('postal_code'))
      return object.postal_code = ac.long_name;
    if (ac.types.includes('administrative_area_level_1'))
      return object.aa_level_1 = ac.long_name;
    if (ac.types.includes('administrative_area_level_2')) {
      if (ac.short_name) {
        object.state_code = ac.short_name;
      }
      return object.aa_level_2 = ac.long_name;
    }
    if (ac.types.includes('administrative_area_level_3'))
      return object.aa_level_3 = ac.long_name;
    if (ac.types.includes('country'))
      return object.country = ac.short_name;
  });

  return object;
}

export function uploadsField(uploads_object, field) {
  return Object.values(uploads_object).map(u => u[field]);
}

export function completedUploads(file) {
  return [file.path, {
    ...UPLOAD_DEFAULT,
    completed: true,
    process: 100,
    name: file.name,
    path: file.path,
    downloadURL: file.url,
    isDeletable: false,
    uuid: file.path,
    isUploaded: true
  }]
}

export function reloadIfUnathorized(error) {
  if (error.response && error.response.status === 403) {
      toast.error("Errore durante l'esecuzione della richiesta, i dati potrebbero non essere aggiornati. La pagina verrà ricaricata", {
          onClose: () => window.location.reload()
      });
  } else {
      console.error(error);
  }
}

export function getStaffUser(role) {
  return axios.get('users', {
      params: { role }
  })
}

export function formatterEuro(value) {
  return `${value.toLocaleString('it-IT')}€`;
};
